import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import Image from "@components/Image.js"

export default function SimpleSection ({ children, subtext, heading, image, cta, reverse }) {

  return (
    <section className="container py-8 md:py-16 lg:py-24">
      <div className={`flex flex-wrap flex-col-reverse justify-center md:justify-between items-center `+(reverse ? 'md:flex-row-reverse' : 'md:flex-row')}>
        <div className="md:w-1/2 px-4">
          {children !== null ? (
            <>
            { subtext && <p className="mb-8">{subtext}</p>}
            { heading && <h2 className="mb-4 text-4xl leading-tight font-semibold">{heading}</h2>}
            {children}
            </>
          ) : (
            <div className="text-center">
              <p className="mb-8">{subtext}</p>
              <h2 className="mb-4 text-4xl leading-tight font-semibold">{heading}</h2>
              {cta !== null && <Link to={cta.url} className="btn">{cta.label}</Link>}
            </div>
          )}
        </div>
        <div className="w-full sm:w-3/4 md:w-1/2 px-4">
          <Image filename={image.src} alt={image.alt} className="mb-4 md:mb-0" />
        </div>
      </div>
    </section>
  )
}

SimpleSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  cta: PropTypes.object,
  reverse: PropTypes.bool,
}

SimpleSection.defaultProps = {
  title: null,
  description: null,
  image: null,
  cta: null,
  reverse: false,
  children: null,
}