import React from "react"
import { Helmet } from "react-helmet"
import { css } from "@emotion/core"

import Layout from "@components/layout"

import FeaturesSlider from "@components/FeaturesSlider.js"
import SimpleSection from "@components/SimpleSection.js"
import Divider from "@components/Divider.js"
import MiniSocial from "@components/MiniSocial.js"

import BgImage from "@assets/images/smoke.png"
import Subscribe from "@components/Subscribe"
import Pricing from "@components/Pricing"

export default function Home() {

  const social = {
    digi: [
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/digitalist_na/',
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/42735839',
      },
      {
        name: 'Facebook',
        url: 'https://www.facebook.com/digitalistCA/',
      },
    ],
    gofox: [{
        name: 'Instagram',
        url: 'https://www.instagram.com/gofox_ai/',
      }],
  }

  const backgroundStyles = css`
    background-image: url(${BgImage});
  `

  const introStyles = css`
    min-height: 640px;
  `
  
  return (
    <main>
      <Helmet>
        <html lang="en" />
        <title>FasTrack: Fast, simple, AI assisted 3D camera tracking for Apple Motion, Adobe After Effects (coming soon) and Blender (COLLADA)</title>
        <meta name="description" content="FasTrack creates full 3D camera solves. Use FasTrack for 360 degree and 2D footage to take your VR experiences to the next level." />
      </Helmet>
      <Layout>

        <div>

          <div className="w-full top-0 left-0 bg-black bg-cover bg-center z-10" css={backgroundStyles}>
            <div className="container relative flex items-center justify-center" css={introStyles}>
              <div className="sm:w-2/3 md:w-3/4 lg:w-7/12 px-4 text-center mt-32 sm:mt-20">
                <h1 className="mb-4 sm:text-lg text-white text-shadow">FasTrack: Fast, simple, AI assisted 3D camera tracking for Apple&nbsp;Motion, Adobe&nbsp;After&nbsp;Effects&nbsp; and Blender&nbsp;(COLLADA) on Windows and Mac.</h1>
                <a href="/#pricing" className="btn">Buy Now</a>
              </div>
            </div>
          </div>

          <section className="py-4 sm:py-8 md:py-16">

            <h2 className="text-3xl font-bold text-center tracking-wide" id="features">Features</h2>

            <FeaturesSlider />
            
          </section>

          <Divider right />

          <section className="py-4 sm:py-8 md:py-16">
            <Pricing />
          </section>

          <Divider />

          <SimpleSection reverse image={{src:'boxart@2x.png', alt:'FasTrack box art'}}>
            <div className="text-center" key="documentation"  id="documentation">
              <h2 className="mb-4 text-4xl leading-tight font-semibold">Documentation</h2>
              <p>Video Tutorial: <a className="underline" href="https://www.youtube.com/watch?v=U9xkh6bEKV0">YouTube</a></p>
              <p className="mb-4">User guide: <a className="underline" href="/user-guide">Download</a></p>
              <p className="text-sm mb-2">Requirements</p>
              <p className="text-sm mb-2">MacOs 10.14+ / Windows 10+; 1.5-2.2 GB disk space; 1600x900 resolution; 8GB RAM</p>
              <p className="text-sm">Apple Motion: 5.5+, After Effects 17.0+, Blender 2.9+</p>
            </div>
          </SimpleSection>

          <Divider right />

          <SimpleSection heading="Contact Us" image={{src:'gofox-team.jpg', alt:'Members of the GOFOX Ai / Digitalist team'}}>
            <div className="mb-8 text-sm md:w-2/3" key="contact-us"  id="contact-us">
              <p className="mb-3">GOFOX Ai partnered with Digitalist to bring FasTrack to life.</p>
              <p className="mb-3">We are a team of scientists, software developers and artists dedicated to bringing the best AI.</p>
            </div>
            <div className="mb-4 mt-1 font-semibold text-gray-500 leading-none">
              Email us: <a href="mailto:contact@gofox.ai">contact@gofox.ai</a>
            </div>
            <div className="flex items-center">  
              <span className="mt-1 font-semibold text-gray-500 leading-none">Follow GOFOX Ai</span>
              <MiniSocial links={social.gofox} company="GOFOX Ai" />
            </div>
          </SimpleSection>

          <section className="bg-black py-12 sm:py-16 md:py-20">
            <div className="container">
              <div className="w-full sm:w-3/4 md:w-3/5 lg:w-1/2 px-4 mx-auto">
                <h2 id="sign-up" className="mb-2 text-blue-500 font-semibold text-lg">Sign Up for Updates</h2>
                <Subscribe />
              </div>
            </div>
          </section>

        </div>

      </Layout>
      
    </main>
  )
}