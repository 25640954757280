import React, { Component } from "react"
import Spinner from "./Spinner";

import { css } from "@emotion/core"
import Label from "./parts/Label";

const setupUrl = `/.netlify/functions/commerce/setup`;
const createCheckoutUrl = `/.netlify/functions/commerce/create-checkout-session`;

const saleStyles = css`
  width: 12rem;
  height: 1.5rem;
  line-height: 1.5rem;
  right: -4.25rem;
  top: 1rem;
`

export default class Pricing extends Component {

  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      selected: {},
      customerEmail: '',
      customerName: '',
      customerInCanada: false,
      customerRegion: '',
      publishableKey: null,
      tiers: {},
      tierNames: ['monthly', 'annual', 'lifetime'],
      baseTiers: {
        'monthly':
        {
          'priceId': '001',
          'name': 'Monthly',
          'footnote': '',
          'price': null,
          'priceAfter': '',
          'promo': null,
          'color': 'gray-700',
        },
        'annual':
        {
          'priceId': '002',
          'name': 'Annual',
          'footnote': '',
          'price': null,
          'priceAfter': '',
          'promo': null,
          'originalPrice': '19.99',
          'color': 'blue-500',
        },
        'lifetime':
        {
          'priceId': '003',
          'name': 'Full License*',
          'footnote': '',
          'price': null,
          'promo': null,
          'originalPrice': '399.99',
          'color': 'blue-900',
        },
      },
      regions: {
        'CA/AB':'Alberta',
        'CA/BC':'British Columbia',
        'CA/MB':'Manitoba',
        'CA/NB':'New Brunswick',
        'CA/NL':'Newfoundland and Labrador',
        'CA/NT':'Northwest Territories',
        'CA/NS':'Nova Scotia',
        'CA/NU':'Nunavut',
        'CA/ON':'Ontario',
        'CA/PE':'Prince Edward Island',
        'CA/QC':'Quebec',
        'CA/SK':'Saskatchewan',
        'CA/YT':'Yukon',
      },
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {

    let request = new Request( setupUrl );

    this.setState({
      'loadingPrices': true,
    });

    // load Stripe JS
    const stripeJs = document.createElement('script'); 
    stripeJs.src = 'https://js.stripe.com/v3/'; 
    stripeJs.async = true;

    fetch( request )
      .then(response => response.json())
      .then(data => {
        let tiers = {...this.state.baseTiers};
        // pull direct array on refactor
        Object.keys(tiers).forEach( (t) => {
          // ensure tier exists in response
          if( data.prices[t] ) {
            let tier = tiers[t];
            // price ID
            tier.priceId = data.prices[t].id;
            // price
            tier.price = data.prices[t].unit_amount / 100;
            // type
            tier.type = data.prices[t].type;
            // subscription or one_time
            if( tier.type === 'recurring' && data.prices[t].recurring ) {
              tier.priceAfter = '/mo';
              // detect if item is really annual
              if ( data.prices[t].recurring.interval === 'year' ) {
                // normalize for monthly price
                tier.price = tier.price / 12;
                tier.footnote = 'Billed annually';
              // WARN: assume it's either yearly or annually
              } else {
                tier.footnote = 'Billed monthly';
              }
            } else {
              tier.footnote = 'Single full license';
            }
            // detect sale by checking the product nickname attr
            tier.sale = data.prices[t].nickname.toLowerCase().includes('sale');
            // reinsert
            tiers[t] = tier;
          }
        });
        // init stripe JS
        document.body && document.body.appendChild(stripeJs); 
        stripeJs.onload = () => {
          window.stripe = window.Stripe(data.publishableKey);
        }
        // save updated state
        this.setState({
          'tiers': tiers,
          'publishableKey': data.publishableKey,
          'loadingPrices': false,
        });
      });

  }

  async handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // wait for setstate to complete
    await this.setState({
      [name]: value
    });
    // now proceed to validate
    let canCheckout = false;
    // conditions
    if( this.state.customerName &&
      /(.+)@(.+){2,}\.(.+){2,}/.test(this.state.customerEmail) &&
      (
        !this.state.customerInCanada ||
        ( this.state.customerInCanada && this.state.customerRegion )
      )
    ) {
      canCheckout = true;
    }
    // this is used to enable/disable checkout button
    this.setState({
      'canCheckout': canCheckout
    });
  }

  selectProduct( tierName )
  {
    this.setState({
      'selected': this.state.tiers[tierName],
      'step': 2, 
    })
  }

  clearSelection()
  {
    this.setState({
      'selected': {},
      'step': 1, 
    })
  }

  createCheckout( event )
  {
    event.preventDefault();
    if(this.state.loading) {
      return
    }
    this.setState({'loading': true});

    // determine payment mode based on price object type
    let paymentMode = null;
    if( this.state.selected.type === 'recurring' ) {
      paymentMode = 'subscription'
    } else if( this.state.selected.type === 'one_time' ) {
      paymentMode = 'payment'
    } else {
      console.log(`Unknown price type ${this.state.selected.type}`);
      return;
    }

    // firstpromoter
    if( window.$FPROM ) {
      window.$FPROM.trackSignup({
        email: this.state.customerEmail
      }, function(){console.log('Callback received!')});
    }

    fetch( createCheckoutUrl , {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        priceId: this.state.selected.priceId,
        paymentMode: paymentMode,
        customerEmail: this.state.customerEmail,
        customerName: this.state.customerName,
        customerRegion: this.state.customerRegion,
      })
    })
    .then(response => response.json())
    .then(data => {
      window.stripe
        .redirectToCheckout({sessionId: data.sessionId})
        .then(data);
    });

  }

  render()
  {

    return (
      <section className="container" data-step={this.state.step}>
        <h2 className="mb-4 text-3xl font-bold text-center tracking-wide" id="pricing">Pricing and Plans</h2>
        <p className="mb-8 px-4 text-center">Early bird special: Save 25% on annual plans or 30% off a full license including 1 year of updates.</p>
        { this.state.loadingPrices && <Spinner className="block w-5 mx-auto" />}
        <div className="flex flex-wrap mb-8 sm:px-4 md:px-0 sm:-mx-2 md:-mx-4">
          {Object.keys( this.state.tiers ).map((key) => {
            let tier = this.state.tiers[key];
            return (
              <React.Fragment key={`${key}-${tier.priceId}`}>
                { (this.state.step === 1 || this.state.selected.priceId === tier.priceId) &&
                  <div className="w-full mb-4 sm:w-1/3 px-4 sm:px-2 md:px-4">
                    <div className={`relative overflow-hidden pb-4 bg-white flex flex-col text-center ${this.state.step === 1 ? 'h-full' : ''}`}>
                      { tier.sale &&
                      <div className="absolute overflow-hidden font-semibold uppercase text-sm text-white bg-red-500 text-center transform rotate-45 text-shadow" css={saleStyles}>
                        Sale
                      </div>}
                      <h3 className={`p-4 mb-4 text-xl md:text-2xl font-semibold text-white text-shadow bg-${tier.color}`}>{tier.name}</h3>
                      <div className="px-4 mb-8">
                        <p className="text-3xl sm:text-2xl md:text-4xl font-semibold">
                          <span className={`text-${tier.color}`}>${tier.price}</span>
                          {tier.priceAfter && <span className="font-normal text-lg text-gray-500">{tier.priceAfter}</span>}
                        </p>
                          {tier.sale && tier.originalPrice && <p className="text-gray-700"><strike>Regular: ${tier.originalPrice}</strike></p>}
                      </div>
                      <div className="mt-auto px-4">
                        <p className="mb-8">{tier.footnote}</p>
                        { this.state.step === 1 &&
                          <button className="block w-full btn btn-light text-lg" onClick={(e) => this.selectProduct(key, e)}>Buy Now</button>
                        }
                        { this.state.step === 2 && 
                          <button className="inline-block underline text-gray-900 p-2" onClick={(e) => this.clearSelection(e)}>Change Plan</button>
                        }
                      </div>
                    </div>
                  </div>
                }
              </React.Fragment>
            )
          })}

          {/* get user name and email */}
          {this.state.step === 2 &&
            <div className="w-full mb-4 sm:w-2/3 px-4" key={`step2`}>
              <div className="bg-white p-4">
                <h2 className="text-xl font-semibold mb-4">FasTrack License Details</h2>
                <p className="mb-4">Please provide additional details for the FasTrack license.</p>
                <form className="flex flex-wrap -mx-4" action="post" onSubmit={(e) => this.createCheckout(e)}>
                  <div className="w-full sm:w-1/2 px-4 flex flex-wrap flex-col">
                    <Label htmlFor="customerName" required>Full Name</Label>
                    <input type="text"
                      id="customerName"
                      name="customerName"
                      required
                      className="control"
                      value={this.state.customerName}
                      onChange={e => this.handleChange(e)} />
                  </div>
                  <div className="w-full sm:w-1/2 px-4 flex flex-wrap flex-col">
                    <Label htmlFor="customerEmail" required>Email Address</Label>
                    <input type="email"
                      id="customerEmail"
                      name="customerEmail"
                      required
                      className="control"
                      value={this.state.customerEmail}
                      onChange={e => this.handleChange(e)} />
                  </div>
                  <div className="w-full sm:w-1/2 px-4 flex flex-wrap flex-col">
                    <Label htmlFor="customerInCanada" styleName="mb-4">
                      <input type="checkbox"
                        id="customerInCanada"
                        name="customerInCanada"
                        value={this.state.customerInCanada}
                        onChange={e => this.handleChange(e)} />
                      <span className="ml-2">Are you located in Canada?</span>
                    </Label>
                    {this.state.customerInCanada &&
                    <>
                      <Label htmlFor="customerRegion" required>Province</Label>
                      <select id="customerRegion"
                        name="customerRegion"
                        className="control"
                        onBlur={e => this.handleChange(e)}
                        onChange={e => this.handleChange(e)}
                        >
                        <option value="">Please select...</option>
                        {Object.keys( this.state.regions ).map(( key ) => {
                          return (<option value={ key } key={ key }>{ this.state.regions[key] }</option>)
                        })}
                      </select>
                    </>}
                  </div>
                  <div className="w-full mt-4 px-4 flex flex-wrap flex-col">
                    <div className="flex items-center">
                      <button className="btn btn-light text-lg"
                        disabled={!this.state.canCheckout}
                        onClick={(e) => this.createCheckout(e)}>Proceed to Checkout</button>
                      {this.state.loading && <Spinner className="ml-2" />}
                    </div>
                  </div>
                </form>
              </div>
            </div>}
        </div>

        <p className="text-center text-gray-700">
          * Full license comes with 1 year of updates and maintenance.
        </p>
      </section>
    )
  }

}