import React from "react"
import PropTypes from "prop-types"

import { css } from "@emotion/core"

const Divider = ({ right }) => {

  const dividerStyles = css`
    width: 100vw;
  `

  return (
    <div className="w-full overflow-hidden">
      <div className="px-4 lg:px-0 container" css={ right ? {"direction": "rtl"} : null }>
        <div className="border-b border-gray-600" css={dividerStyles}></div>
      </div>
    </div>
  )
}

export default Divider

Divider.propTypes = {
  right: PropTypes.bool,
}

Divider.defaultProps = {
  right: false,
}