import React, { Component } from 'react'
import Slider from "react-slick";
import { css } from "@emotion/core"

import "@node_modules/slick-carousel/slick/slick.css"; 
import "@node_modules/slick-carousel/slick/slick-theme.css";

import FeatureItem from "@components/FeatureItem.js"

export default class FeaturesSlider extends Component {

  state = {
    activeSlide: 0,
    totalSlides: 0,
    slideToShow: 2.5, // default
    fadeNext: false,
    content: [
      {
        image: 'tracking@2x.png',
        icon: 'content-360deg.svg',
        title: 'Camera Solver for 360 and 2D',
        description: 'Use FasTrack on your 360 degree footage to elevate your VR experiences, or augment reality in standard 2D footage. FasTrack works with portrait or landscape video at any resolution.',
      },
      {
        image: 'masking@2x.png',
        icon: 'artboard.svg',
        title: 'AI Assisted Tracking',
        description: 'Complex scenes with multiple moving objects are difficult and time consuming to track. FasTrack lets you automatically focus tracking where it counts using deep learning.',
      },
      {
        image: 'accelerated@2x.png',
        icon: 'speedometer.svg',
        title: 'Accelerated Workflow',
        description: 'FasTrack can track HD footage in seconds. It has a simple, elegant workflow so you’ll have time to focus on what counts in your creative process. Export to Apple Motion, Adobe After Effects or Blender (COLLADA) to bring your creation to life.',
      },
      {
        image: '2d-portrait@2x.png',
        icon: 'journey-05.svg',
        title: '2D Portrait Video Tracking',
        description: 'Quickly and easily track your portrait mode shots to seamlessly add 3D objects and animations for next level social media content.',
      },
    ],
  }

  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)

    this.featureStyles = css`
      max-width: 1440px;
    `

    this.state.totalSlides = this.state.content.length
  }

  previous() {
    if( this.state.activeSlide !== 0 )
      this.slider.slickPrev();
  }

  next() {
    if( !this.state.fadeNext )
      this.slider.slickNext();
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: this.state.slideToShow,
      slidesToScroll: this.state.slideToShow,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1, 
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1, 
          }
        }
      ],
      afterChange: (current) => {
        // calculate if next button should be faded
        // custom to handle this offset number of slides
        // 1.5 is hardcoded (messy)
        let fadeNext = false
        if (
          ( current % 1 !== 0 && current >= (this.state.totalSlides - this.state.slideToShow) )
            ||
          ( current >= this.state.totalSlides - 1 )
            ||
          ( this.slider.state.breakpoint === 1000 && current >= (this.state.totalSlides - 2) )
        ) {
          fadeNext = true
        }
        this.setState({
          'activeSlide': current,
          'fadeNext': fadeNext,
        })
      },
    };
    return (
      <div className="mx-auto" css={this.featureStyles}>

        <Slider ref={c => (this.slider = c)} {...settings}>
          
          {this.state.content.map( (contentProps, index) => {
            return (<FeatureItem {...contentProps} key={index} />)
          })}
        </Slider>

        <div className="my-4 md:my-8 px-4 lg:px-0 container flex justify-end">
          <button onClick={this.previous}
            className={this.state.activeSlide === 0 ? 'opacity-30 cursor-default' : 'opacity-90 hover:opacity-100'}>
            <img src={require(`@assets/svgs/prev.svg`)} alt="Previous button" width="50" height="50"/>
            <span className="sr-only">Previous</span>
          </button>
          <button onClick={this.next} className={'ml-2 ' + (this.state.fadeNext ? 'opacity-30 cursor-default' : 'opacity-90 hover:opacity-100')}>
            <img src={require(`@assets/svgs/next.svg`)} alt="Next button"  width="50" height="50"/>
            <span className="sr-only">Next</span>
          </button>
        </div>

      </div>
    )
  }

}