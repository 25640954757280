import React from "react";

export default function Label ({ children, styleName, htmlFor, required }) {

  return (
    <label htmlFor={ htmlFor }
      className={`label ${styleName}`}>
      { children }
      { required &&
        <span className="text-red-500">
          *
          <span className="sr-only"> Required</span>
        </span>}
    </label>
  )

}