import React from "react"
import PropTypes from "prop-types"

import { css } from "@emotion/core"

import Image from "@components/Image.js"

const FeatureItem = ({ title, description, image, icon }) => {

  const featureStyles = css`
    max-width: 480px;
  `

  return (
    <div className="" css={featureStyles}>
      { image !== null && <Image filename={image} alt={title} className="mb-6" eager />}
      <div className="sm:w-3/4 mx-auto px-4">
        { icon !== null && <img src={require(`@assets/svgs/${icon}`)}  className="mb-4" alt={`Icon for ${title}`} width="33" height="33" />}
        <h3 className="mb-4 text-xl font-semibold">{title}</h3>
        <p className="text-sm">{description}</p>
      </div>
    </div>
  )
}

export default FeatureItem

FeatureItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.string,
}

FeatureItem.defaultProps = {
  title: null,
  description: null,
  image: null,
  icon: null,
}