import React from "react"
import PropTypes from "prop-types"

import { css } from "@emotion/core"

const MiniSocial = ({ links, company }) => {

  const iconStyles = css`
    width: 1.5rem;
    height: 1.5rem;
  `

  return (
    <div className="inline-flex">
      {links.map( (link, index) => {
        return (<a className={ `text-sm mx-1` } href={link.url} target="_blank" rel="noreferrer noopener" aria-label={`${company} ${link.name}`} css={iconStyles} key={index}>
          <img src={require(`@assets/svgs/${link.name.toLowerCase()}.svg`)} alt={`${link.name} icon`} width="24" height="24"/>
          <span className="sr-only">{`${company} ${link.name}`}</span>
        </a>)
      }
      )}
    </div>
  )
}

export default MiniSocial

MiniSocial.propTypes = {
  links: PropTypes.array,
  company: PropTypes.string,
}

MiniSocial.defaultProps = {
  links: [],
  company: '',
}