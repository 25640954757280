import React from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class Subscribe extends React.Component {
  state = {
    name: null,
    email: null,
    complete: false,
    completeMessage: '',
    error: false,
    errorMessage: '',
  }

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    // simple reset
    this.setState({
      'error': false,
      'errorMessage': '',
    })

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        if (result !== 'success') {
          throw msg
        }
        this.setState({
          'complete': true,
          'completeMessage': msg,
        })
      })
      .catch(err => {
        this.setState({
          'error': true,
          'errorMessage': err,
        })
      })
  }

  render () {
    return (
      <form onSubmit={this._handleSubmit} className="flex flex-col">
        { this.state.complete ?
          (
            <div className="bg-blue-300 p-2 text-center text-sm text-blue-900">
              { this.state.completeMessage }
            </div>
          ) : (
            <>
              <label htmlFor="email-address" className="mb-2 text-sm text-gray-400">Email Address</label>
              <div className="flex">
                <input onChange={this._handleChange}
                      type="email"
                      name="email"
                      id="email-address"
                      className="mr-2 px-2 py-1 flex-grow text-blue-900 bg-white opacity-90" />
                <button type="submit" className="btn">Sign Up</button>
              </div>
              { this.state.error ? (
                <div className="mt-2 bg-red-300 p-2 text-center text-sm text-red-900">
                  { this.state.errorMessage }
                </div>
              ) : null}
            </>
          )
        }
      </form>
    )
  }
}